import React from 'react'

export const FuelOutlineSvg = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none">
    <path
      d="M0.500977 6C0.500977 4.067 2.06798 2.5 4.00098 2.5H7.81069C8.67534 2.5 9.50938 2.82005 10.1521 3.39847L15.9323 8.60068C16.6698 9.26443 17.0909 10.21 17.0909 11.2022V17.239C17.0909 19.172 15.5239 20.739 13.5909 20.739H4.00097C2.06798 20.739 0.500977 19.172 0.500977 17.239V6Z"
      stroke="currentColor"
    />
    <path
      d="M17.0898 6C17.0898 4.067 15.5228 2.5 13.5898 2.5H8.24518H3.9999C2.0669 2.5 0.499893 4.067 0.499893 6V17.239C0.499893 19.172 2.0669 20.739 3.99989 20.739H13.5898C15.5228 20.739 17.0898 19.172 17.0898 17.239V6Z"
      stroke="currentColor"
    />
    <rect x="3.00049" width="5" height="1" rx="0.5" fill="currentColor" />
    <path
      d="M10.8341 10.5412C11.8814 11.5885 11.8814 13.2865 10.8341 14.3338C9.78682 15.3811 8.08883 15.3811 7.04155 14.3338C5.99426 13.2865 5.99426 11.5885 7.04155 10.5412L8.93783 8.64494L10.8341 10.5412Z"
      stroke="currentColor"
    />
  </svg>
)
