import React from 'react'

export const PhoneSvg = () => (
  <svg fill="none" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.67 2.691L5.446 1.144C4.566.032 3.026-.054 2.024.948l-.225.225C.132 2.84-.266 5.827.98 7.838l.003.005.028.061c.026.053.064.129.118.227.106.197.272.482.513.84.481.718 1.26 1.732 2.459 2.93 1.198 1.198 2.211 1.977 2.929 2.458.359.24.643.407.84.514a5.723 5.723 0 00.288.146l.007.003c2.024 1.215 4.992.852 6.662-.817l.214-.214c1.002-1.002.923-2.555-.173-3.453L13.36 9.302c-1.082-.887-2.753-.803-3.74.184l-.06.06a.136.136 0 01-.19.001L6.455 6.632a.133.133 0 01-.003-.185l.004-.005c.98-.99 1.074-2.664.214-3.75zm-3.209-.305c.088-.088.159-.105.204-.103.044.003.112.027.187.123l1.224 1.546c.105.133.16.33.147.54a.814.814 0 01-.214.522v.001a2.166 2.166 0 00.009 3.055l2.915 2.915a2.169 2.169 0 003.064-.001l.06-.06a.759.759 0 01.498-.202.763.763 0 01.517.153l1.509 1.236c.097.08.128.157.131.216.003.059-.02.139-.107.226l-.214.214c-.5.5-1.236.817-2.014.911-.777.094-1.568-.038-2.172-.404l-.105-.063-.112-.048c-.098-.042-.38-.193-.825-.492-.782-.525-1.666-1.25-2.624-2.208-.958-.958-1.683-1.842-2.208-2.624-.298-.445-.45-.727-.491-.825l-.054-.124-.072-.116c-.365-.583-.497-1.364-.4-2.141.096-.776.418-1.518.922-2.023l.225-.224z"
      clipRule="evenodd"
    />
  </svg>
)
