import React from 'react'

export const MailSvg = () => (
  <svg fill="none" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      d="M2.703 3.647c1.001.546 1.692.948 2.07 1.205L8 7.042l3.229-2.191c.378-.256 1.069-.657 2.07-1.205l-.787.121A20 20 0 019.47 4H6.529a20 20 0 01-3.04-.233l-.786-.12zM14.148 4.72c-.595.781-1.142 1.342-1.643 1.682L9.123 8.696a2 2 0 01-2.246 0l-3.38-2.293c-.5-.34-1.049-.901-1.645-1.685l.079.953c.046.552.069 1.107.069 1.66V12h12V7.332c0-.554.023-1.109.07-1.661l.078-.951zM1.5 2h13A1.5 1.5 0 0116 3.5v9a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 12.5v-9A1.5 1.5 0 011.5 2z"
    />
  </svg>
)
