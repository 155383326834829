import React from 'react'

export const CloudUpdatesSvg = () => (
  <svg fill="none" viewBox="0 0 80 80">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M52.41 61.75h12.408C72.64 61.75 79 55.302 79 47.656c0-5.012-2.798-9.57-7.167-12.004-.831-8.803-8.43-15.72-17.651-15.72-2.663 0-5.242.585-7.614 1.719C42.614 16.3 36.212 13 29.364 13 17.634 13 8.09 22.327 8.09 33.794c0 .606.031 1.218.097 1.844C3.79 38.048 1 42.57 1 47.656 1 55.302 7.36 61.75 15.182 61.75H27.59m10.227 7.058h3.9c1.078 0 1.95-.93 1.95-2.08V56.193h2.925c.72 0 1.38-.422 1.72-1.099a2.2 2.2 0 00-.097-2.134L41.39 40.484c-.361-.579-.97-.926-1.622-.926-.651 0-1.26.347-1.623.926L31.32 52.96a2.2 2.2 0 00-.097 2.134c.339.676 1 1.099 1.72 1.099h2.925v10.535c0 1.15.872 2.08 1.95 2.08z"
    />
  </svg>
)
