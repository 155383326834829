import React from 'react'

export const PaypalSvg = () => (
  <svg viewBox="0 0 504 128.8">
    <path
      fill="#139ad6"
      d="M389.6 33.6h-27.2c-1.6 0-3.2 1.6-4 3.2l-11.2 70.4a2.121 2.121 0 002.4 2.4H364a2.121 2.121 0 002.4-2.4l3.2-20c0-1.6 1.6-3.2 4-3.2h8.8q27.6 0 31.2-26.4c1.6-7.2 0-13.6-3.2-17.6-4.8-4-12-6.4-20.8-6.4m3.2 26.4c-1.6 9.6-8.8 9.6-16 9.6H372l3.2-18.4c0-.8.8-1.6 2.4-1.6h1.6c4.8 0 9.6 0 12 3.2 1.6.8 1.6 3.2 1.6 7.2"
    />
    <path
      fill="#263b80"
      d="M193.6 33.6h-27.2c-1.6 0-3.2 1.6-4 3.2l-11.2 70.4a2.121 2.121 0 002.4 2.4h12.8c1.6 0 3.2-1.6 4-3.2l3.2-19.2c0-1.6 1.6-3.2 4-3.2h8.8q27.6 0 31.2-26.4c1.6-7.2 0-13.6-3.2-17.6-4.8-4-11.2-6.4-20.8-6.4m3.2 26.4c-1.6 9.6-8.8 9.6-16 9.6h-4l3.2-18.4c0-.8.8-1.6 2.4-1.6h1.6c4.8 0 9.6 0 12 3.2.8.8 1.6 3.2.8 7.2m79.2-.8h-12.8c-.8 0-2.4.8-2.4 1.6l-.8 4-.8-1.6c-3.2-4-8.8-5.6-15.2-5.6-14.4 0-27.2 11.2-29.6 26.4a23.845 23.845 0 004.8 20c4 4.8 9.6 6.4 16.8 6.4 12 0 18.4-7.2 18.4-7.2l-.8 4a2.121 2.121 0 002.4 2.4h12c1.6 0 3.2-1.6 4-3.2l7.2-44.8c-.8-.8-2.4-2.4-3.2-2.4m-18.4 25.6c-1.6 7.2-7.2 12.8-15.2 12.8a12.793 12.793 0 01-8.8-3.2q-2.4-3.6-2.4-9.6A14.651 14.651 0 01245.6 72c4 0 6.4 1.6 8.8 3.2 2.4 2.4 3.2 6.4 3.2 9.6"
    />
    <path
      fill="#139ad6"
      d="M471.2 59.2h-12.8c-.8 0-2.4.8-2.4 1.6l-.8 4-.8-1.6c-3.2-4-8.8-5.6-15.2-5.6-14.4 0-27.2 11.2-29.6 26.4a23.845 23.845 0 004.8 20c4 4.8 9.6 6.4 16.8 6.4 12 0 18.4-7.2 18.4-7.2l-.8 4a2.121 2.121 0 002.4 2.4h12c1.6 0 3.2-1.6 4-3.2l7.2-44.8c-.8-.8-1.6-2.4-3.2-2.4m-18.4 25.6c-1.6 7.2-7.2 12.8-15.2 12.8a12.793 12.793 0 01-8.8-3.2q-2.4-3.6-2.4-9.6A14.651 14.651 0 01440.8 72c4 0 6.4 1.6 8.8 3.2a9.182 9.182 0 013.2 9.6"
    />
    <path
      fill="#263b80"
      d="M345.6 59.2H332a4.156 4.156 0 00-3.2 1.6L311.2 88l-8-25.6c-.8-1.6-1.6-2.4-4-2.4h-12.8c-1.6 0-2.4 1.6-2.4 3.2l14.4 42.4-13.6 19.2c-.8 1.6 0 4 1.6 4h12.8a4.156 4.156 0 003.2-1.6l44-63.2c2.4-2.4.8-4.8-.8-4.8"
    />
    <path
      fill="#139ad6"
      d="M486.4 36l-11.2 72a2.121 2.121 0 002.4 2.4h11.2c1.6 0 3.2-1.6 4-3.2L504 36.8a2.121 2.121 0 00-2.4-2.4h-12.8c-.8-.8-1.6 0-2.4 1.6"
    />
    <path
      fill="#263b80"
      d="M92 9.6C86.4 3.2 76 0 61.6 0h-40c-2.4 0-4.8 2.4-5.6 4.8L0 109.6c0 2.4 1.6 4 3.2 4H28l6.4-39.2V76c.8-2.4 3.2-4.8 5.6-4.8h12c23.2 0 40.8-9.6 46.4-36v-2.4c.8-10.4-.8-16.8-6.4-23.2"
    />
    <path
      fill="#139ad6"
      d="M97.6 32.8v2.4c-5.6 27.2-23.2 36-46.4 36h-12c-2.4 0-4.8 2.4-5.6 4.8l-8 48.8a2.93 2.93 0 003.2 3.2h20.8c2.4 0 4.8-1.6 4.8-4v-.8l4-24.8v-1.6c0-2.4 2.4-4 4.8-4h3.2c20 0 36-8 40-32 1.6-9.6.8-18.4-4-24a8.193 8.193 0 00-4.8-4"
    />
    <path
      fill="#232c65"
      d="M92 30.4c-.8 0-1.6-.8-2.4-.8a2.939 2.939 0 01-2.4-.8C84 28 80.8 28 76.8 28H45.6a2.939 2.939 0 00-2.4.8 4.4 4.4 0 00-2.4 4l-6.4 41.6V76c.8-2.4 3.2-4.8 5.6-4.8h12c23.2 0 40.8-9.6 46.4-36a2.939 2.939 0 01.8-2.4c-1.6-.8-2.4-1.6-4-1.6-2.4-.8-2.4-.8-3.2-.8"
    />
  </svg>
)
