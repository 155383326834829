import React from 'react'

export const SyncSvg = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5641 7.97592C20.7589 8.46819 20.3962 9.0019 19.8667 9.0019H14.8148C14.2131 9.0019 13.8564 8.32902 14.194 7.83101L15.2025 6.34365C14.2445 5.80078 13.1474 5.5019 12.0001 5.5019C8.89621 5.5019 6.24205 7.69456 5.63085 10.6975C5.5207 11.2387 4.99269 11.5882 4.4515 11.478C3.91031 11.3679 3.56088 10.8399 3.67103 10.2987C4.47095 6.36841 7.9408 3.5019 12.0001 3.5019C13.5557 3.5019 15.042 3.92304 16.3276 4.68417L17.384 3.12609C17.7221 2.62743 18.4805 2.7108 18.7022 3.271L20.5641 7.97592ZM7.67265 19.3196L6.61626 20.8777C6.27816 21.3764 5.51981 21.293 5.29811 20.7328L3.43617 16.0279C3.24135 15.5356 3.60412 15.0019 4.13354 15.0019H9.18549C9.78717 15.0019 10.1439 15.6748 9.80626 16.1728L8.7978 17.6602C9.75582 18.203 10.8529 18.5019 12.0001 18.5019C15.1069 18.5019 17.7628 16.3053 18.371 13.2987C18.4805 12.7573 19.0081 12.4073 19.5494 12.5168C20.0907 12.6263 20.4408 13.1539 20.3313 13.6952C19.5353 17.6302 16.0632 20.5019 12.0001 20.5019C10.4445 20.5019 8.95827 20.0808 7.67265 19.3196Z"
      fill="currentColor"
    />
  </svg>
)
