import React from 'react'

export const ShieldCheckmarkSvg = () => (
  <svg fill="none" viewBox="0 0 35 38">
    <path
      stroke="currentColor"
      strokeWidth="3"
      d="M17.5 36.5c-.732 0-2.118-.46-3.866-1.538-1.684-1.04-3.504-2.53-5.067-4.294C4.171 25.705 1.5 18.767 1.5 9.763c0-.388.26-.735.647-.842 6.162-1.71 11.109-4.104 14.776-7.21a.895.895 0 011.154 0c3.667 3.106 8.614 5.5 14.776 7.21a.877.877 0 01.647.842c0 9.004-2.671 15.941-7.067 20.905-1.563 1.765-3.383 3.254-5.067 4.294C19.618 36.04 18.232 36.5 17.5 36.5z"
    />
    <path stroke="currentColor" strokeWidth="3" d="M11.5 18.5l4.655 5L25 14" />
  </svg>
)
