import React from 'react'

export const OpenBoxSvg = () => (
  <svg viewBox="0 0 60 42" fill="none">
    <path
      d="M52.3641 7.34691L30.0001 0L7.63618 7.34691L0 19.3677L7.02981 21.6772V34.454L30 42L52.9702 34.454V21.6772L60 19.3677L52.3641 7.34691ZM45.6254 8.80395L30.0001 13.937L14.3749 8.80395L30.0001 3.6709L45.6254 8.80395ZM5.36659 17.4599L9.54189 10.887L27.3168 16.7264L23.1416 23.2992L5.36659 17.4599ZM10.5458 22.8323L24.6496 27.4655L28.2422 21.8101V37.7517L10.5458 31.9382V22.8323ZM31.758 37.7516V21.8099L35.3506 27.4654L49.4545 22.8321V31.9381L31.758 37.7516ZM36.8586 23.2993L32.6834 16.7265L50.4583 10.8871L54.6336 17.4601L36.8586 23.2993Z"
      fill="currentColor"
    />
  </svg>
)
