import React from 'react'

export const DynoWheelSvg = () => (
  <svg fill="none" viewBox="0 0 30 30">
    <path
      fill="currentColor"
      d="M28.828 15.469a.625.625 0 010-1.25h1.146a14.824 14.824 0 00-3.958-9.453l-.833.833a.573.573 0 01-.443.182.61.61 0 01-.619-.625c0-.164.063-.323.176-.442l.833-.834A14.944 14.944 0 0015.625 0v1.172a.625.625 0 11-1.25 0V0a14.789 14.789 0 00-9.531 3.932c.026 0 .026 0 .052.026l.86.86a.645.645 0 010 .885.63.63 0 01-.886 0l-.86-.86a.09.09 0 01-.026-.051A14.741 14.741 0 000 14.4a.236.236 0 01.13-.026h1.198a.625.625 0 010 1.25H0c.14 3.586 1.574 7 4.037 9.61 0-.053.026-.079.078-.13l.86-.86a.624.624 0 011.02.203.626.626 0 01-.136.682L5 26.016a.2.2 0 01-.13.052A15.086 15.086 0 0014.557 30a.234.234 0 01-.026-.13v-1.198a.624.624 0 111.25 0v1.302a14.995 14.995 0 009.532-4.063c-.026 0-.026 0-.052-.026l-.86-.859a.627.627 0 11.886-.885l.859.859c.003 0 .007 0 .01.002a.024.024 0 01.009.006.024.024 0 01.007.018A14.784 14.784 0 0030 15.47h-1.172z"
    />
    <path
      fill="#fff"
      d="M15 6c-4.963 0-9 4.037-9 9 0 4.962 4.037 9 9 9 4.962 0 9-4.038 9-9 0-4.963-4.038-9-9-9zm4.604 3.362A.561.561 0 0120 9.177a.556.556 0 01.409.153 7.832 7.832 0 012.291 4.067.563.563 0 01-.292.617 4.052 4.052 0 01-1.83.422c-.816 0-1.59-.234-2.038-.682-.954-.953-.556-2.595 1.064-4.392zm-9.208 11.364a.562.562 0 01-.396.186h-.022a.562.562 0 01-.387-.154A7.832 7.832 0 017.3 16.691a.563.563 0 01.292-.617c1.268-.654 3.076-.532 3.868.26.953.953.555 2.595-1.064 4.392zm1.064-6.972c-.448.448-1.222.682-2.038.682a4.055 4.055 0 01-1.83-.422.563.563 0 01-.292-.617A7.833 7.833 0 019.59 9.33a.542.542 0 01.41-.153.561.561 0 01.395.185c1.62 1.797 2.018 3.44 1.064 4.392zm5.937 8.747a7.883 7.883 0 01-4.794 0 .564.564 0 01-.391-.575c.14-2.024 1.339-3.551 2.788-3.551 1.45 0 2.648 1.526 2.788 3.55a.564.564 0 01-.39.576zM12.75 15c0-1.24 1.01-2.25 2.25-2.25s2.25 1.01 2.25 2.25-1.01 2.25-2.25 2.25-2.25-1.01-2.25-2.25zm5.038-6.881c-.14 2.023-1.339 3.55-2.788 3.55-1.45 0-2.648-1.527-2.788-3.55a.562.562 0 01.39-.575 7.891 7.891 0 014.796 0 .562.562 0 01.39.575zm4.912 8.483a7.832 7.832 0 01-2.291 4.067.562.562 0 01-.387.154H20a.561.561 0 01-.396-.186c-1.62-1.797-2.018-3.44-1.064-4.393.792-.792 2.6-.913 3.868-.26.225.117.345.37.292.618z"
    />
  </svg>
)
