import React from 'react'

export const GraphSvg = () => (
  <svg fill="none" viewBox="0 0 80 80">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M53.55 45.896c4.717.87 8.74 3.14 12.088 5.458a26.429 26.429 0 003.928 2.238c1.293.591 2.233.872 2.655.919a2 2 0 01-.442 3.975c-1.078-.12-2.492-.623-3.877-1.257a30.424 30.424 0 01-4.54-2.586c-3.152-2.181-6.629-4.093-10.537-4.814-3.854-.71-8.27-.292-13.384 2.434-1.62.863-3.695 2.597-6.158 4.862-.942.868-1.95 1.822-2.99 2.806-1.558 1.473-3.187 3.014-4.773 4.432-2.678 2.395-5.49 4.68-8.207 6.17-2.66 1.458-5.677 2.402-8.515 1.338a2 2 0 011.404-3.746c1.162.436 2.832.192 5.188-1.1 2.298-1.26 4.83-3.288 7.464-5.644 1.574-1.407 3.095-2.847 4.6-4.271 1.044-.99 2.081-1.971 3.121-2.928 2.429-2.235 4.853-4.313 6.984-5.449 5.886-3.137 11.22-3.718 15.991-2.837z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M65.772 21.932c-1.85-.873-4.128-1.402-7.507-.951-2.617.35-5.09 1.32-7.379 3.463-2.32 2.173-4.565 5.655-6.497 11.212-4.308 12.385-9.163 18.412-12.285 22.286-.368.458-.713.885-1.029 1.29a2 2 0 11-3.15-2.466c.325-.415.674-.848 1.044-1.308 3.057-3.794 7.562-9.385 11.642-21.117 2.068-5.944 4.6-10.064 7.542-12.817 2.975-2.785 6.252-4.063 9.582-4.508 4.121-.55 7.177.087 9.743 1.298 1.812.855 3.42 2.038 4.83 3.078.466.343.91.67 1.334.965a2 2 0 11-2.284 3.283 76.27 76.27 0 01-1.694-1.221c-1.312-.967-2.461-1.812-3.892-2.487z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 7.5a2 2 0 012 2V68h66.5a2 2 0 110 4H9v4a2 2 0 11-4 0v-4H2a2 2 0 110-4h3V9.5a2 2 0 012-2z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.5 41.5a2 2 0 012-2h5a2 2 0 110 4h-5a2 2 0 01-2-2zm16 0a2 2 0 012-2h5a2 2 0 110 4h-5a2 2 0 01-2-2zm18-2a2 2 0 100 4h5a2 2 0 100-4h-5zm14 2a2 2 0 012-2h5a2 2 0 110 4h-5a2 2 0 01-2-2zm18-2a2 2 0 100 4h5a2 2 0 100-4h-5zM7 3a2 2 0 011.544.729l4.515 5.485a2 2 0 01-3.088 2.542L7 8.146l-2.971 3.61A2 2 0 11.94 9.214l4.515-5.485A2 2 0 017 3zM79 70a2 2 0 01-.729 1.544l-5.485 4.515a2 2 0 11-2.542-3.088L73.854 70l-3.61-2.971a2 2 0 112.542-3.088l5.485 4.515A2 2 0 0179 70z"
      clipRule="evenodd"
    />
  </svg>
)
