import React from 'react'

export const ArrowBackSvg = () => (
  <svg viewBox="0 0 16 12" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.70991 1.9099C7.10164 1.51844 7.10036 0.882891 6.70738 0.49268C6.31646 0.104509 5.68495 0.105359 5.2954 0.49491L0.497146 5.29319C0.106623 5.68371 0.106624 6.31688 0.497146 6.7074L5.2954 11.5057C5.68495 11.8952 6.31649 11.896 6.70741 11.5079C7.10037 11.1177 7.10171 10.4822 6.71003 10.0907L5.29669 8.67809C4.76504 8.14672 4.17518 7.67699 3.53828 7.2778L2.79093 6.80938L2.83751 6.76279L3.79648 6.89979C4.26491 6.96671 4.7375 7.00029 5.21069 7.00029H15C15.5523 7.00029 16 6.55258 16 6.00029C16 5.44801 15.5523 5.00029 15 5.00029H5.21069C4.7375 5.00029 4.26491 5.03388 3.79648 5.1008L2.83751 5.23779L2.79065 5.19093L3.54104 4.72037C4.17697 4.32159 4.76597 3.85247 5.29693 3.32189L6.70991 1.9099Z"
    />
  </svg>
)
