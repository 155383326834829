import React from 'react'

export const AppFeatureSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 106 65">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M1 7.829A6.829 6.829 0 017.829 1h22.726A4.06 4.06 0 0133.5 2.266v0a4.06 4.06 0 002.945 1.266h20.11A4.06 4.06 0 0059.5 2.266v0A4.06 4.06 0 0162.445 1H93c6.627 0 12 5.373 12 12v38.663c0 6.628-5.373 12-12 12H62.445a4.06 4.06 0 01-2.945-1.265v0a4.06 4.06 0 00-2.945-1.266h-20.11a4.06 4.06 0 00-2.945 1.266v0a4.06 4.06 0 01-2.945 1.265H7.829A6.829 6.829 0 011 56.835v0c0-.555.45-1.004 1.004-1.004h88.047a7 7 0 007-7V15.833a7 7 0 00-7-7H2.004C1.45 8.833 1 8.383 1 7.829v0zM1.138 56.01V8.379"
    />
    <rect
      width="63.261"
      height="39.143"
      x="19.216"
      y="12.516"
      stroke="currentColor"
      strokeWidth="2"
      rx="2"
    />
  </svg>
)
