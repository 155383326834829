import React from 'react'

export const GearSvg = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M19.0861 11.8505L19.5 11.7782V11.358V9.10665V8.6816L19.0805 8.61318L16.7193 8.22803C16.5817 7.77197 16.4002 7.33498 16.1774 6.92132L17.5566 4.95542L17.7978 4.61154L17.5007 4.31461L15.9098 2.72461L15.6094 2.42432L15.2645 2.67232L13.3297 4.06354C12.9146 3.83834 12.4736 3.65363 12.0123 3.51578L11.5996 1.16359L11.527 0.75H11.1071H8.85665H8.43157L8.36317 1.16955L7.98195 3.50788C7.521 3.64422 7.07953 3.82608 6.66325 4.04956L4.73329 2.67137L4.38874 2.42532L4.08927 2.72461L2.49837 4.31461L2.20172 4.61109L2.44207 4.9548L3.80057 6.89745C3.57312 7.31684 3.38749 7.76085 3.24753 8.22637L0.918047 8.61342L0.5 8.68288V9.10665V11.3571V11.7764L0.912876 11.8495L3.24302 12.2618C3.38217 12.7281 3.56799 13.1729 3.79525 13.5927L2.42068 15.5146L2.17418 15.8592L2.47371 16.1589L4.06461 17.7507L4.36125 18.0475L4.70501 17.8069L6.65114 16.4444C7.06877 16.6694 7.51101 16.8532 7.97397 16.9905L8.36343 19.332L8.43295 19.75H8.85665H11.1071H11.5263L11.5994 19.3372L12.0166 16.9826C12.4768 16.8429 12.9181 16.6578 13.3344 16.4311L15.2944 17.8074L15.6384 18.0489L15.9355 17.7515L17.5255 16.1597L17.8258 15.8591L17.5775 15.5142L16.1802 13.5736C16.4023 13.1599 16.5839 12.7221 16.7205 12.2643L19.0861 11.8505ZM12.6374 10.25V10.2503C12.6382 11.7078 11.4571 12.8883 10 12.8883C8.54291 12.8883 7.36169 11.7078 7.36169 10.25C7.36169 8.794 8.54291 7.61259 10 7.61259C11.4569 7.61259 12.6374 8.79382 12.6374 10.25Z"
      stroke="currentColor"
    />
  </svg>
)
