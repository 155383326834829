import React from 'react'

export const ChipsCompareSvg = () => (
  <svg fill="none" viewBox="0 0 33 22">
    <path
      stroke="currentColor"
      d="M1.5 17.5v-11H24A1.5 1.5 0 0125.5 8v8a1.5 1.5 0 01-1.5 1.5H1.5z"
    />
    <path
      stroke="currentColor"
      d="M.5 5.518A3.018 3.018 0 013.518 2.5h2.968c.187 0 .374.025.555.075l1.732.475a4.5 4.5 0 001.191.16h10.604a4.5 4.5 0 001.237-.173l1.408-.402a3.5 3.5 0 01.962-.135H25A3.5 3.5 0 0128.5 6v12a3.5 3.5 0 01-3.5 3.5h-.749a3.5 3.5 0 01-1.087-.173l-1.275-.417a4.5 4.5 0 00-1.397-.222H10.038c-.457 0-.911.07-1.347.206l-1.628.51c-.202.064-.411.096-.622.096h-2.87A3.071 3.071 0 01.5 18.429V17.5a1 1 0 001-1V7.425a1 1 0 00-1-1v-.907z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.531 2h1.955c.232 0 .463.031.687.093l1.732.475a4 4 0 001.059.143h10.604a4 4 0 001.1-.154l1.407-.403a4 4 0 011.1-.154H25a4 4 0 014 4v12c0 .72-.19 1.394-.522 1.977A5 5 0 0032.996 15V5a5 5 0 00-5-5H21.61l-1.485.722H13.42L11.831 0H7.997c-1.48 0-2.773.804-3.465 2z"
      clipRule="evenodd"
    />
  </svg>
)
