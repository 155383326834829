import React from 'react'

export const WhatsAppSvg = () => (
  <svg viewBox="0 0 20 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9975 0H10.0025C15.5162 0 20 4.48625 20 10C20 15.5138 15.5162 20 10.0025 20C7.96875 20 6.0825 19.395 4.50125 18.3475L0.6575 19.5763L1.90375 15.8612C0.705 14.215 0 12.1875 0 10C0 4.485 4.48375 0 9.9975 0ZM13.854 15.5272C14.6178 15.3622 15.5753 14.7972 15.8165 14.116C16.0578 13.4335 16.0578 12.8522 15.9878 12.7285C15.9312 12.6303 15.7976 12.5659 15.5981 12.4698C15.5461 12.4448 15.4897 12.4177 15.429 12.3872C15.1353 12.241 13.7065 11.5347 13.4365 11.441C13.1715 11.341 12.919 11.376 12.719 11.6585C12.681 11.7117 12.643 11.7651 12.6053 11.8182C12.364 12.1575 12.1313 12.4848 11.9378 12.6935C11.7615 12.881 11.474 12.9047 11.2328 12.8047C11.2065 12.7937 11.1763 12.7816 11.1426 12.7679C10.7606 12.6137 9.91333 12.2715 8.88777 11.3585C8.02277 10.5885 7.43527 9.6297 7.26527 9.3422C7.09836 9.05379 7.24233 8.88468 7.37539 8.72837C7.37786 8.72548 7.38032 8.72259 7.38277 8.7197C7.46856 8.61356 7.55181 8.52517 7.63548 8.43635C7.69566 8.37245 7.75606 8.30833 7.81777 8.2372C7.82694 8.22664 7.83585 8.21639 7.84453 8.20641C7.97557 8.05574 8.0536 7.96602 8.14152 7.77845C8.24152 7.5847 8.17027 7.3847 8.09902 7.2372C8.05 7.13391 7.74717 6.39916 7.4872 5.76841C7.37596 5.4985 7.27257 5.24764 7.20027 5.07345C7.00652 4.6097 6.85902 4.5922 6.56527 4.5797C6.55627 4.57925 6.54719 4.57879 6.53801 4.57833C6.44515 4.57363 6.34289 4.56845 6.23027 4.56845C5.84777 4.56845 5.44902 4.68095 5.20777 4.9272C5.19934 4.93581 5.19055 4.94475 5.18143 4.95402C4.87279 5.26791 4.18527 5.96713 4.18527 7.36095C4.18527 8.75746 5.17615 10.109 5.35949 10.3591C5.36454 10.366 5.36898 10.372 5.37277 10.3772C5.3838 10.3917 5.40459 10.4218 5.43485 10.4656C5.80921 11.0078 7.63256 13.6483 10.3515 14.7747C12.649 15.7272 13.3315 15.6385 13.854 15.5272Z"
      fill="currentColor"
    />
  </svg>
)
