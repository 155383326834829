import React from 'react'

export const CheckmarkSvg = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path
      d="M17.715 0.386427C17.8806 0.182958 18.1195 0.052691 18.3802 0.0237051C18.6409 -0.00528075 18.9026 0.0693362 19.1088 0.231472C19.315 0.393607 19.4492 0.630277 19.4826 0.890467C19.5159 1.15066 19.4457 1.41353 19.287 1.62243L8.66404 15.1364C8.5265 15.3113 8.35178 15.4535 8.15252 15.5525C7.95327 15.6515 7.73448 15.705 7.51201 15.709C7.28954 15.713 7.06896 15.6675 6.86625 15.5757C6.66355 15.484 6.4838 15.3483 6.34004 15.1784L1.23604 9.14643C1.14766 9.04673 1.0801 8.93037 1.03732 8.80419C0.994546 8.67801 0.977412 8.54456 0.98693 8.41167C0.996448 8.27878 1.03243 8.14913 1.09275 8.03033C1.15307 7.91154 1.23653 7.806 1.33821 7.71991C1.43989 7.63382 1.55775 7.56892 1.68487 7.52901C1.81198 7.48911 1.94579 7.47501 2.07843 7.48754C2.21107 7.50007 2.33987 7.53898 2.45727 7.60199C2.57466 7.66499 2.67828 7.75082 2.76204 7.85443L7.47004 13.4184L17.715 0.386427Z"
      fill="currentColor"
    />
  </svg>
)
