import React from 'react'

export const MonitorWithStandSvg = () => (
  <svg fill="none" viewBox="0 0 93 93">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M86.942 10.039H6.058a2.02 2.02 0 00-2.02 2.02v56.543a2.02 2.02 0 002.02 2.02h80.884a2.02 2.02 0 002.02-2.02V12.058a2.02 2.02 0 00-2.02-2.02zM6.058 6A6.058 6.058 0 000 12.058v56.544a6.058 6.058 0 006.058 6.059h80.884A6.058 6.058 0 0093 68.602V12.058A6.058 6.058 0 0086.942 6H6.058z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M1.862 58.505h89.28v4.039H1.862v-4.039zM40.921 66.372a1.86 1.86 0 011.86-1.86h5.58a1.86 1.86 0 110 3.72h-5.58a1.86 1.86 0 01-1.86-1.86z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M61.448 32.172a4.039 4.039 0 00-5.712 0L41.984 45.924a8.078 8.078 0 01-11.424 0l-4.452-4.452a4.039 4.039 0 00-5.712 0l-5.946 5.946-2.856-2.856 5.946-5.946a8.078 8.078 0 0111.424 0l4.452 4.452a4.039 4.039 0 005.712 0L52.88 29.316a8.078 8.078 0 0111.424 0l4.452 4.452a4.039 4.039 0 005.712 0l4.086-4.086 2.856 2.856-4.086 4.086a8.078 8.078 0 01-11.424 0l-4.452-4.452zM27.262 84.245H65.01v4.039H27.262v-4.039z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M40.389 74.66v13.127h-4.04V74.66h4.04zM56.544 74.66v13.127h-4.039V74.66h4.039z"
      clipRule="evenodd"
    />
  </svg>
)
