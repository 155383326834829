import React from 'react'

export const ChatContourSvg = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.792543 15.4707C0.827006 15.4986 0.869743 15.5142 0.914042 15.5151C2.67897 15.5518 4.07485 15.2154 5.1017 14.506C6.00035 14.826 6.97779 15 8 15C12.4183 15 16 11.75 16 7.5C16 3.25 12.4183 0 8 0C3.58172 0 0 3.25 0 7.5C0 9.44658 0.751378 11.1834 1.99037 12.4942C1.92136 13.3636 1.51212 14.262 0.762696 15.1896C0.6933 15.2754 0.706663 15.4013 0.792543 15.4707ZM8 13C7.53142 13 7.07242 12.953 6.62892 12.8613C5.50222 12.6283 5.03782 12.7347 4.10013 13.4045C3.72835 13.6701 3.06435 13.932 3.06435 13.932C3.06435 13.932 4.05318 11.9739 3.06435 10.6735C2.37475 9.76661 2 8.67551 2 7.5C2 4.4265 4.61423 2 8 2C11.3858 2 14 4.4265 14 7.5C14 10.5735 11.3858 13 8 13Z"
      fill="currentColor"
    />
  </svg>
)
