import React from 'react'

export const InfinityOutlineSvg = () => (
  <svg fill="none" viewBox="0 0 80 80">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M20.66 29.458c1.775-.018 3.53.366 5.117 1.123a10.844 10.844 0 013.986 3.23l4.696 5.822.506.628-.506.628-4.7 5.828-.003.003a11.366 11.366 0 01-4.097 3.223 12.142 12.142 0 01-5.191 1.138 12.134 12.134 0 01-5.181-1.177 11.348 11.348 0 01-4.074-3.256 10.456 10.456 0 01-2.068-4.66 10.17 10.17 0 01.416-5.044 10.71 10.71 0 012.804-4.299 11.66 11.66 0 014.55-2.629l3.745-.558zm0 0a12.589 12.589 0 00-3.745.558l3.745-.558zm20.06 17.975l-.774-.972-.784.966-2.954 3.64-.004.007c-2.444 3.058-5.869 5.33-9.794 6.483a20.945 20.945 0 01-12.05-.085c-3.905-1.208-7.292-3.529-9.686-6.62C2.282 47.76 1 44.056 1 40.261c0-3.795 1.282-7.5 3.674-10.591 2.394-3.092 5.78-5.412 9.686-6.62a20.944 20.944 0 0112.05-.085c3.925 1.152 7.35 3.425 9.794 6.483l.004.006 2.954 3.64.784.966.775-.972 2.903-3.64.004-.005c1.82-2.31 4.203-4.19 6.962-5.487A20.806 20.806 0 0159.492 22c3.095.01 6.14.702 8.889 2.016 2.747 1.314 5.117 3.211 6.919 5.532 1.801 2.32 2.986 4.999 3.462 7.817a16.95 16.95 0 01-.713 8.417c-.946 2.716-2.57 5.192-4.743 7.224-2.174 2.032-4.838 3.56-7.776 4.457a22.12 22.12 0 01-6.316.937 20.797 20.797 0 01-8.732-1.962c-2.707-1.273-5.054-3.111-6.86-5.365 0 0 0 0 0 0l-2.902-3.64zm4.695-7.795l-.498.623.498.624 4.655 5.833v.001c1.45 1.825 3.47 3.165 5.768 3.845a12.24 12.24 0 007.052-.038c2.29-.705 4.294-2.068 5.721-3.908 1.428-1.842 2.203-4.067 2.203-6.357s-.775-4.514-2.203-6.356c-1.427-1.84-3.432-3.204-5.72-3.909a12.24 12.24 0 00-7.053-.037c-2.297.679-4.318 2.02-5.767 3.844l-.001.001-4.655 5.834z"
    />
  </svg>
)
