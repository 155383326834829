import React from 'react'

export const Clock4HoursSvg = () => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM11.3575 10.2645C11.6416 9.79092 11.4881 9.17665 11.0145 8.89251L9 7.68381V5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5V8.25C7 8.60126 7.1843 8.92677 7.4855 9.10749L9.9855 10.6075C10.4591 10.8916 11.0733 10.7381 11.3575 10.2645Z"
      fill="currentColor"
    />
  </svg>
)
