import React from 'react'

export const LocationSvg = () => (
  <svg fill="none" viewBox="0 0 16 16">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.002 0C4.016 0 .779 3.186.779 7.124c0 2.72 1.559 5.066 4.022 6.27 1.067.547 1.742 1.198 1.935 1.723a1.347 1.347 0 002.532-.002c.192-.525.87-1.177 1.942-1.725 2.451-1.192 4.014-3.541 4.014-6.265C15.224 3.187 11.987 0 8.002 0zm5.222 7.125c0 1.936-1.107 3.6-2.906 4.476-.992.507-1.969 1.399-2.316 2.649-.353-1.25-1.32-2.14-2.299-2.64l-.017-.009c-1.8-.877-2.907-2.542-2.907-4.477C2.78 4.298 5.114 2 8.002 2c2.887 0 5.222 2.298 5.222 5.125z"
      clipRule="evenodd"
    />
    <path fill="currentColor" d="M8 9a2 2 0 100-4 2 2 0 000 4z" />
  </svg>
)
