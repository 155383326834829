import React from 'react'

export const CartBagSvg = () => (
  <svg viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M7 7c0-2.882 1.987-5.004 5-5 3.014.004 5 2.12 5 5h.193c.81 0 1.215 0 1.53.155a1.5 1.5 0 01.652.627c.168.308.184.713.216 1.522l.302 7.536c.058 1.45.087 2.176-.178 2.732a2.5 2.5 0 01-1.096 1.142c-.546.286-1.272.286-2.723.286H8.102c-1.451 0-2.177 0-2.723-.286a2.5 2.5 0 01-1.096-1.142c-.265-.556-.236-1.281-.177-2.732l.301-7.536c.032-.81.049-1.214.216-1.522a1.5 1.5 0 01.652-.627C5.59 7 5.995 7 6.805 7h.194zm2 0h6c0-1.803-1.121-2.997-3.002-3-1.877-.003-2.999 1.194-2.999 3z"
      clipRule="evenodd"
    />
  </svg>
)
