import React from 'react'

export const MastercardSvg = () => (
  <svg viewBox="0 0 291.791 182.353">
    <g transform="translate(0 -54.719)">
      <path
        d="M182.3,145.9A91.149,91.149,0,1,1,91.149,54.719,91.151,91.151,0,0,1,182.3,145.9Z"
        fill="#e2574c"
      />
      <path
        d="M200.616,54.719A90.646,90.646,0,0,0,146.147,72.9l.073.009c2.991,2.89,6.291,4.924,8.835,8.251l-18.965.3a93.752,93.752,0,0,0-8.233,9.656H161.3a84.724,84.724,0,0,1,7.03,9.081H121.794a90.847,90.847,0,0,0-4.659,9.09h56.493a77.649,77.649,0,0,1,3.921,8.8h-63.76a87.811,87.811,0,0,0-2.535,9.646H180.22c.675,3.155,1.194,6.072,1.55,9.045H109.886c-.3,3-.456,6.045-.456,9.118h72.859a80.092,80.092,0,0,1-.556,9.118H109.886q.465,4.637,1.368,9.118H180.11q-1.084,4.558-2.562,9.008H113.579a88.506,88.506,0,0,0,3.428,9.1h56.621a83.052,83.052,0,0,1-5.306,9.081H121.631a88.709,88.709,0,0,0,6.081,9.154l33.589.5c-2.863,3.437-6.537,5.507-9.884,8.516.182.146-5.352-.018-16.248-.191A91.154,91.154,0,1,0,200.616,54.719Z"
        fill="#f4b459"
      />
    </g>
  </svg>
)
