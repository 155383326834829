import React from 'react'

export const SteeringWheelSvg = () => (
  <svg fill="none" viewBox="0 0 30 30">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.898 0c2.08.123 4.046.67 5.813 1.557l-1.2 2.08A12.564 12.564 0 0015.898 2.4V0zm-1.795 2.4a12.564 12.564 0 00-4.614 1.238l-1.2-2.08A14.899 14.899 0 0114.103 0v2.4zm9.161.057a15.097 15.097 0 014.255 4.258l-2.075 1.2a12.626 12.626 0 00-3.38-3.38l1.2-2.078zm5.154 5.813A14.958 14.958 0 0130 14.987C30 23.277 23.284 30 15 30 6.716 30 0 23.278 0 14.987c0-2.414.57-4.696 1.582-6.716l2.076 1.2c-.224.46-.42.937-.59 1.43 1.852 1.597 2.447 1.867 4.253 1.931 2.239.08 4.275-1.584 7.679-1.584s5.44 1.664 7.679 1.584c1.806-.064 2.4-.334 4.253-1.93a12.54 12.54 0 00-.59-1.432l2.076-1.2zM7.935 4.536a12.63 12.63 0 00-3.38 3.378L2.482 6.715a15.1 15.1 0 014.255-4.258l1.2 2.079zm19.625 11.55c-3.068.263-5.734 4.865-3.917 8.088a12.587 12.587 0 003.917-8.088zM6.357 24.174c1.817-3.223-.85-7.825-3.917-8.088a12.589 12.589 0 003.917 8.088zm5.836-2.926c-1.652 0-3.933 3.857-.253 4.658 1.912.416 4.208.416 6.12 0 3.68-.801 1.4-4.658-.253-4.658h-5.614z"
      clipRule="evenodd"
    />
    <circle cx="15" cy="15" r="13.5" stroke="currentColor" strokeWidth="3" />
  </svg>
)
