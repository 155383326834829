import React from 'react'

export const InfinitySvg = () => (
  <svg fill="none" viewBox="0 0 36 18">
    <path
      fill="currentColor"
      d="M26.646 18a9.481 9.481 0 01-4.12-.964 9.2 9.2 0 01-3.247-2.646l-1.306-1.707-1.33 1.707a9.236 9.236 0 01-4.632 3.196 9.497 9.497 0 01-5.682-.042 9.225 9.225 0 01-4.581-3.264A8.798 8.798 0 010 9.029c0-1.886.611-3.723 1.748-5.251A9.225 9.225 0 016.329.513a9.497 9.497 0 015.682-.041 9.236 9.236 0 014.632 3.196l1.33 1.707 1.306-1.707A9.197 9.197 0 0122.574.962a9.484 9.484 0 014.2-.962 9.482 9.482 0 014.192.99 9.19 9.19 0 013.275 2.73 8.856 8.856 0 011.646 3.873c.227 1.4.11 2.83-.34 4.178a8.953 8.953 0 01-2.252 3.574 9.308 9.308 0 01-3.675 2.196c-.96.301-1.964.456-2.974.459zM20.79 9.029l2.095 2.735a4.73 4.73 0 002.37 1.645 4.864 4.864 0 002.914-.017 4.725 4.725 0 002.351-1.671 4.506 4.506 0 00.898-2.692c0-.967-.314-1.91-.898-2.693a4.725 4.725 0 00-2.35-1.67 4.864 4.864 0 00-2.914-.017 4.73 4.73 0 00-2.371 1.645l-2.095 2.735zM9.299 4.434a5.021 5.021 0 00-1.555.24A4.755 4.755 0 005.872 5.8a4.574 4.574 0 00-1.144 1.827 4.469 4.469 0 00-.17 2.132c.118.714.406 1.39.843 1.976a4.696 4.696 0 001.672 1.39 4.846 4.846 0 004.282.017 4.699 4.699 0 001.684-1.378l2.117-2.735-2.117-2.735a4.47 4.47 0 00-1.633-1.38 4.616 4.616 0 00-2.107-.48z"
    />
  </svg>
)
