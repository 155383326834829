import React, { FC } from "react";

import "./BannerMain.sass";

const BannerComponent: FC = () => {
  return (
    <section className="banner" id="first">
      <div className="wrapper">
        <div className="apps__description">
          <h1 className="js-animate apps__item apps__item_animation">
            Мы — <span>команда</span> аналитиков, программистов, тестировщиков и
            исследователей
          </h1>
          <p className="js-animate apps__item apps__item_animation">
            Разработка мобильных приложений для крупного бизнеса и стартапов. Разноплановый опыт работы с продуктом: банковский финтех с нуля, разработка клиентского сервиса для крупного страхового бизнеса, создание собственного продукта и другое. 
            Среди наших клиентов как российские, так и иностранные компании: Альфа-групп, Модульбанк, Складно, Unilever, StarHub, Ooredoo и другие. 
          </p>
        </div>
      </div>
    </section>
  );
};
export { BannerComponent };
