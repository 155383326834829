import React from 'react'

export const CloudUpdatesFilledSvg = () => (
  <svg width="40" height="33" viewBox="0 0 40 33" fill="none">
    <path
      d="M34.3335 11.8043L34.4323 12.8518L35.3515 13.3637C36.9863 14.2741 38 15.9592 38 17.7725C38 20.5886 35.6338 23 32.7273 23H7.27273C4.36623 23 2 20.5886 2 17.7725C2 15.9193 3.0107 14.2601 4.64726 13.3632L5.814 12.7238L5.67514 11.4006C5.64877 11.1494 5.63636 10.9049 5.63636 10.6635C5.63636 5.93055 9.59121 2 14.5455 2C17.4188 2 20.1067 3.38768 21.7597 5.62473L22.7411 6.95271L24.2308 6.24057C25.1763 5.78862 26.2041 5.5545 27.2727 5.5545C30.9963 5.5545 34.0068 8.34377 34.3335 11.8043Z"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      d="M16.9134 13.515L13.4338 19.8755C12.8943 20.7863 12.8561 21.918 13.33 22.8636C13.7496 23.7006 14.5446 24.3248 15.4998 24.4885V27.9337C15.4998 29.5047 16.7236 31 18.4999 31H20.5C22.2763 31 23.5 29.5047 23.5 27.9337V24.4885C24.4552 24.3248 25.2502 23.7008 25.6698 22.8639C26.1443 21.9176 26.1053 20.7859 25.5663 19.8756L22.0866 13.515L22.0587 13.464L22.0279 13.4147C21.4971 12.5656 20.5608 12 19.5 12C18.4392 12 17.5029 12.5656 16.9721 13.4147L16.9413 13.464L16.9134 13.515Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="4"
    />
  </svg>
)
