import React from 'react'

export const MonteGtSvg = () => (
  <svg fill="none" viewBox="0 0 29 21">
    <path
      stroke="currentColor"
      d="M1.5 16V5H24a1.5 1.5 0 011.5 1.5v8A1.5 1.5 0 0124 16H1.5z"
    />
    <path
      stroke="currentColor"
      d="M.5 4.018A3.018 3.018 0 013.518 1h2.968c.187 0 .374.025.555.075l1.732.475a4.5 4.5 0 001.191.16h10.604a4.5 4.5 0 001.237-.173l1.408-.402A3.5 3.5 0 0124.175 1H25a3.5 3.5 0 013.5 3.5v12A3.5 3.5 0 0125 20h-.749a3.5 3.5 0 01-1.087-.173l-1.275-.417a4.5 4.5 0 00-1.397-.222H10.038c-.457 0-.911.07-1.347.206l-1.628.51c-.202.064-.411.096-.622.096h-2.87A3.071 3.071 0 01.5 16.929V16a1 1 0 001-1V5.925a1 1 0 00-1-1v-.907z"
    />
  </svg>
)
