import React from 'react'

export const ProcessorBoldSvg = () => (
  <svg fill="none" viewBox="0 0 31 31">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.5 8A4.5 4.5 0 018 3.5h15A4.5 4.5 0 0127.5 8v15a4.5 4.5 0 01-4.5 4.5H8A4.5 4.5 0 013.5 23V8zM8 6.5A1.5 1.5 0 006.5 8v15A1.5 1.5 0 008 24.5h15a1.5 1.5 0 001.5-1.5V8A1.5 1.5 0 0023 6.5H8z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5 15.5a4 4 0 118 0 4 4 0 01-8 0zm4-1a1 1 0 100 2 1 1 0 000-2zM9.5 0A1.5 1.5 0 008 1.5v3a1.5 1.5 0 103 0v-3A1.5 1.5 0 009.5 0zm6 0A1.5 1.5 0 0014 1.5v3a1.5 1.5 0 003 0v-3A1.5 1.5 0 0015.5 0zM20 1.5a1.5 1.5 0 013 0v3a1.5 1.5 0 01-3 0v-3zM9.5 25A1.5 1.5 0 008 26.5v3a1.5 1.5 0 003 0v-3A1.5 1.5 0 009.5 25zm4.5 1.5a1.5 1.5 0 013 0v3a1.5 1.5 0 01-3 0v-3zm7.5-1.5a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 003 0v-3a1.5 1.5 0 00-1.5-1.5zM31 9.5A1.5 1.5 0 0029.5 8h-3a1.5 1.5 0 000 3h3A1.5 1.5 0 0031 9.5zm0 6a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 000 3h3a1.5 1.5 0 001.5-1.5zM29.5 20a1.5 1.5 0 010 3h-3a1.5 1.5 0 010-3h3zM6 9.5A1.5 1.5 0 004.5 8h-3a1.5 1.5 0 100 3h3A1.5 1.5 0 006 9.5zM4.5 14a1.5 1.5 0 010 3h-3a1.5 1.5 0 010-3h3zM6 21.5A1.5 1.5 0 004.5 20h-3a1.5 1.5 0 000 3h3A1.5 1.5 0 006 21.5z"
      clipRule="evenodd"
    />
  </svg>
)
